import React, { useEffect, useRef, useState, useReducer } from "react";
import { ObjectService, ObjectSubscriptionService, ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
import { getAllChambers, getAllRecipesByChamber, getAllSequences } from "../../api";
import DicingPressureModeDTDL from "./dicing/dicingPressureModeDTDL";
import DicingBiasPoverModel from "./dicing/dicingBiasPoverModel";
import DicingGasboxDTDL from "./dicing/dicingGasboxDTDL";
import DicingChamberDTDL from "./dicing/dicingChamberDTDL";
import DicingMoterModelDTDL from "./dicing/dicingMoterModelDTDL";
import { Tooltip } from "react-tooltip"
import 'react-tooltip/dist/react-tooltip.css'

const objectId = "dtmi:com:via:Module:Dicing RIE Chamber;1"; //TODO: make it configurable

const viaConfigChannel = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const viaConfigobject = {
  objectServiceOptions: {
    apiToken: 'test',
    baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
    wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB
  }
};
const objectService = new ObjectService(viaConfigobject.objectServiceOptions);
const objectSubscriptionService = new ObjectSubscriptionService(viaConfigobject.objectServiceOptions);

const channelService = new ChannelService(viaConfigChannel.channelServiceOptions);
const channelSubscriptionService = new ChannelSubscriptionService(viaConfigChannel.channelServiceOptions);

const defaultObjectChannels = [
   {
    object: "DicingChamberMFCValues",
    name: "DicingChamberMFCValues_MFC7",
    readChannelName: "DicingChamberMFCValues_MFC7",
    feedBackChannel: "DicingChamberMFCValues_MFC7",
    writeChannelName: "DicingChamberMFCValues_MFC7",
    currentValue: "",
  },  
  {
    object: "DicingChamberMFCValues",
    name: "DicingChamberMFCValues_MFC8",
    readChannelName: "DicingChamberMFCValues_MFC8",
    feedBackChannel: "DicingChamberMFCValues_MFC8",
    writeChannelName: "DicingChamberMFCValues_MFC8",
    currentValue: "",
  }, 
  {
    object: "DicingChamberMFCValues",
    name: "DicingChamberMFCValues_MFC9",
    readChannelName: "DicingChamberMFCValues_MFC9",
    feedBackChannel: "DicingChamberMFCValues_MFC9",
    writeChannelName: "DicingChamberMFCValues_MFC9",
    currentValue: "",
  }, 
  {
    object: "DicingChamberMFCValues",
    name: "DicingChamberMFCValues_MFC10",
    readChannelName: "DicingChamberMFCValues_MFC10",
    feedBackChannel: "DicingChamberMFCValues_MFC10",
    writeChannelName: "DicingChamberMFCValues_MFC10",
    currentValue: "",
  }, 
  {
    object: "DicingChamberMFCValues",
    name: "DicingChamberMFCValues_MFC11",
    readChannelName: "DicingChamberMFCValues_MFC11",
    feedBackChannel: "DicingChamberMFCValues_MFC11",
    writeChannelName: "DicingChamberMFCValues_MFC11",
    currentValue: "",
  },
  {
    object: "DicingChamberMFCValues",
    name: "DicingChamberMFCValues_MFC12",
    readChannelName: "DicingChamberMFCValues_MFC12",
    feedBackChannel: "DicingChamberMFCValues_MFC12",
    writeChannelName: "DicingChamberMFCValues_MFC12",
    currentValue: "",
  }, 
   {
    object: "MFC7",
    name: "MAIN.MFC7.Out.Flow_SP",
    readChannelName: "MAIN.MFC7.Out.Flow_SP",
    feedBackChannel: "MAIN.MFC7.Out.Flow_SP",
    currentValue: "",
  },  
  {
   object: "MFC8",
   name: "MAIN.MFC8.Out.Flow_SP",
   readChannelName: "MAIN.MFC8.Out.Flow_SP",
   feedBackChannel: "MAIN.MFC8.Out.Flow_SP",
   currentValue: "",
 }, 
 {
  object: "MFC9",
  name: "MAIN.MFC9.Out.Flow_SP",
  readChannelName: "MAIN.MFC9.Out.Flow_SP",
  feedBackChannel: "MAIN.MFC9.Out.Flow_SP",
  currentValue: "",
}, 
{
 object: "MFC10",
 name: "MAIN.MFC10.Out.Flow_SP",
 readChannelName: "MAIN.MFC10.Out.Flow_SP",
 feedBackChannel: "MAIN.MFC10.Out.Flow_SP",
 currentValue: "",
}, 
{
 object: "MFC11",
 name: "MAIN.MFC11.Out.Flow_SP",
 readChannelName: "MAIN.MFC11.Out.Flow_SP",
 feedBackChannel: "MAIN.MFC11.Out.Flow_SP",
 currentValue: "",
}, 
{
 object: "MFC12",
 name: "MAIN.MFC12.Out.Flow_SP",
 readChannelName: "MAIN.MFC12.Out.Flow_SP",
 feedBackChannel: "MAIN.MFC12.Out.Flow_SP",
 currentValue: "",
}, 
  {
    object: 'DicingChamberVent_Valve',
    name: 'DicingChamberVent_Valve',
    readChannelName: 'MAIN.DicingChamberVent_Valve.Out.Open_cmd', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberVent_Valve.Out.Open_cmd',
    feedBackChannel: 'MAIN.DicingChamberVent_Valve.Out.Open_cmd', //to be changed to in channel
    currentValue: null
  },
  //Newly added - TurboPurge
  {
    object: 'DicingChamberTurboPurge_Valve', // this is for TurboPurge
    name: 'DicingChamberTurboPurge_Valve',
    readChannelName: 'MAIN.DicingChamberTurboPurge_Valve.Out.Open_cmd', //to be changed to in channel
    writeChannelName: 'MAIN.DicingChamberTurboPurge_Valve.Out.Open_cmd',
    feedBackChannel: 'MAIN.DicingChamberTurboPurge_Valve.Out.Open_cmd', //to be changed to in channel
    currentValue: null
  },
  {
    object: "PM1",
    name: "PM1.WaferID",
    feedBackChannel: "PM1.WaferID",
    currentValue: "",
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepLoopCounter",
    feedBackChannel: "DicingRecipeSequence_StepLoopCounter",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepLoopTotal",
    feedBackChannel: "DicingRecipeSequence_StepLoopTotal",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_SeqElapsedTime",
    feedBackChannel: "DicingRecipeSequence_SeqElapsedTime",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_SeqTotalTime",
    feedBackChannel: "DicingRecipeSequence_SeqTotalTime",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepNum",
    feedBackChannel: "DicingRecipeSequence_StepNum",
    currentValue: null,
  },
  {
    object: "Sequence",
    name: "DicingRecipeSequence_StepTime",
    feedBackChannel: "DicingRecipeSequence_StepTime",
    currentValue: null,
  },
  {
    object: 'Sequence',
    name: 'Status_DicingPumpdownSeq',
    readChannelName: 'Status_DicingPumpdownSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingPumpdownSeq',
    feedBackChannel: 'Status_DicingPumpdownSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingChamberOnlineSeq',
    readChannelName: 'Status_DicingChamberOnlineSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingChamberOnlineSeq',
    feedBackChannel: 'Status_DicingChamberOnlineSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingVentSeq',
    readChannelName: 'Status_DicingVentSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingVentSeq',
    feedBackChannel: 'Status_DicingVentSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingCyclePurge',
    readChannelName: 'Status_DicingCyclePurge', //to be changed to in channel
    writeChannelName: 'Status_DicingCyclePurge',
    feedBackChannel: 'Status_DicingCyclePurge', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Status_DicingLeakUpRateSeq',
    readChannelName: 'Status_DicingLeakUpRateSeq', //to be changed to in channel
    writeChannelName: 'Status_DicingLeakUpRateSeq',
    feedBackChannel: 'Status_DicingLeakUpRateSeq', //to be changed to in channel
    currentValue: null
  },
  {
    object: 'Sequence',
    name: 'Sequence',
    readChannelName: 'Status_DicingRecipeSequence', //to be changed to in channel
    writeChannelName: 'Status_DicingRecipeSequence',
    feedBackChannel: 'Status_DicingRecipeSequence', //to be changed to in channel
    currentValue: null
  },
 
  {
    object: 'Interlocks',
    name: 'DicingChamberInterlockStatus',
    readChannelName: 'DicingChamberInterlockStatus', //to be changed to in channel
    writeChannelName: 'DicingChamberInterlockStatus',
    feedBackChannel: 'DicingChamberInterlockStatus', //to be changed to in channel
    currentValue: false
  },
];

const defaultObjectDTDL = {
  "DicingChamberPiraniGuage": {
    "Sensor_Value": 0,
    "OP_State": 0
  },
  "DicingChamberManometer": {
    "Sensor_Value": 0,
    "OP_State": 0
  },
  "DicingChamberPumpPiraniGuage": {
    "Sensor_Value": 0,
    "OP_State": 0
  },
  "Dicing_Turbo_iso_Pirani": {
    "Sensor_Value": 0,
    "OP_State": 0
  },
  "DicingForelinePirani2": {
    "Sensor_Value": 0,
    "OP_State": 0
  },
  "DicingChamberSlit_Valve": {
    "OpenValve": 0, //OpenValve  Open
    "CloseValve": 0, //CloseValve   Close
    "OP_State": 0
  },
  "DicingChamberSource_Power": {
    "RF_ON_Indication": false,
    "Actual_Reflected_Power": 0,
    "Actual_Forward_Power": 0,
    "Actual_Setpoint": 0,
    "OP_State": 0
  },
  "DicingChamberVat_Valve_Interlock": {
    "OpenClose": false, //OpenCloseValve  Open bool

  },
  "DicingChamberBias_Power": {
    "RF_ON_Indication": false,
    "Actual_Reflected_Power": 0,
    "Actual_Forward_Power": 0,
    "Actual_Setpoint": 0,
    "OP_State": 0
  },
  "DicingChamberManoIsolation_Valve": {
    // "OpenCloseValve": 0,
    "OpenClose": false
  },

  "DicingChamberTurboPurge_Valve": {
    // "OpenCloseValve": false,
    "OpenClose": false
  },
  "DicingM7Status": {
    "DicingSlitValveCloseStatus": false,
    "DicingSlitValveOpenStatus": false,
    "Lid_Switch_Top_Status_OSSD2": false,
    "Lid_Switch_Top_Status_OSSD1": false,
    "Lid_Switch_Bottom_Status_OSSD2": false,
    "Lid_Switch_Bottom_Status_OSSD1": false,
    "DicingChamberVentValveOpenStatus": false,
    "DicingChamberTurboIsolationValveOpenStatus": false,
    "DicingChamberManometerIsolationValveOpenStatus": false,
    "DicingChamberRoughValveOpenStatus": false,
    "DicingChamberRoughValveCloseStatus": false,
    "DicingChamberRoughPumpStatusFB": false,
    "OP_State": 0
  },
  "DicingChamberThrottleValve": {
    "Actual_Position": 0,   //SetPosition    //Position
    "Actual_Pressure": 0,
    "Control_Mode": 0,
    "Error_Number": 0,
    "General_Warnings": 0,
    "General_Status": 0,
    "Extended_Warnings": 0,
    "OP_State": 0
  },
  "DicingChamberTurbo_Pump": {
    "Rotation": false, //when rotation and normal true then running
    "Normal": false,
    "Acceleration": false,  //true is ramping up
    "Deceleration": false, //true is ramping down
    "Turbo_Speed": 0,   //SetTurboStartStop //turboStartStop
    "OP_State": 0
  },
  "DicingChamberTurboIsolation_Valve": {
    "Open_fbk": false,
    "Close_fbk": false,  //OpenCloseValve //Open
    "OP_State": 0
  },
  "DicingChamberRoughPump_Valve": {
    "Open_fbk": false,
    "Close_fbk": false,  //OpenCloseValve //Open
    "OP_State": 0
  },
  "DicingChamberVent_Valve": {
    "OpenClose": false,  //OpenCloseValve //Open
    "OP_State": 0
  },
  "DicingChamberTurboPurge_Valve": {
    "OpenClose": false,  //OpenCloseValve //Open
    "OP_State": 0
  },
  "DicingM9Command": {
    "DryPumpOnCmd": false,  //DryPumpOnCmd //OnDryPump
    "OP_State": 0
  },
  "DicingM10Command": {
    // "RoughPumpStopCmd": false,  RoughPumpStopCmd //StopRoughPump
    // "RoughPumpErrorResetCmd": false,  RoughPumpErrorResetCmd //RoughPumpErrorReset
    "BackingPumpStopCmd": false,
    "BackingPumpErrorResetCmd": false,
    "BackingPumpRunningStatus":false
  },
  "DicingChamberServoMotor": {
    "Motor_OPState": 0,   //SetProfileTargetPosition  //profileTargetPosition
    "Actual_motor_current": 0,
    "Actual_motor_position": 0,
    "Actual_motor_velocity": 0,
    "Modes_of_operation_display": 0,
    "Status_word": 0, //SetControlWord //controlWord
    "OP_State": 0
  },
}


export default function DicingRIEChamberDTDL() {


  const [motorscreen, setMotorscreen] = useState(false)
  const [modescreen, setModescreen] = useState(false)
  const [sourcescreen, setSourcescreen] = useState(false)
  const [biasscreen, setBiasscreen] = useState(false)
  const [onlineindicator, setOnlineindicator] = useState(false)

  const [time, settime] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [sequences, setSequences] = useState([]);
  const [loopTestSequences, setLoopTestSequences] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [instanceId, setInstanceId] = useState("");
  const [runningStatus, setRunningStatus] = useState("");
  const [currLoop, setCurrLoop] = useState(0);
  const [currentRecipe,setCurrentRecipe]= useState([])

  const [forwardModel, setForwardModel] = useState(null);
  const [forwardvalue, setForwardvalue] = useState(null);

  const [dialogBox, setDialogBox] = useState('home')

  let execIntervalId = 0;
  // -------------------- CHANNEL IMPLIMENTATION----------------START--/
  const gasBoxConfiguration = useRef(defaultObjectChannels);
  const channels = useRef();
  const subscriptionRef = useRef();
  const [gasBoxState, setGasBoxState] = useState();

  const fetchChannelsStates = async () => {
    const response = await channelService.ChannelStates();
    console.log("ChannelStates: ", { response });
    if (response) {
      channels.current = response;
      updateGasBoxStateChannel(channels.current);
    }
  };
  const updateGasBoxStateChannel = (updatedChannels) => {
    let index = -1
    let gasBoxConfigurationState = gasBoxConfiguration.current;
    updatedChannels.forEach((item) => {
      const config = gasBoxConfigurationState.find((obj, objIndex) => {
        index = objIndex;
        return obj.feedBackChannel === item.channelName
      })
      if (config) {
        config.currentValue = item.data.value;
        gasBoxConfigurationState[index] = config;
      }
    })
    setGasBoxState(() => gasBoxConfigurationState);
  }
  const subscribeToNotificationChannel = () => {
    const onNewMessage = (message) => {
      const channel = JSON.parse(message);
      updateGasBoxStateChannel([channel])
    };
    let channelSubscriptions = [];
    gasBoxConfiguration.current.forEach(config => {
      channelSubscriptions.push(config.feedBackChannel);
    })
    const subscription = {
      channels: channelSubscriptions,
      onNewMessage: onNewMessage,
    };
    subscriptionRef.current = subscription;
    channelSubscriptionService.subscribe(subscription);
  };
  const unSubscribe = () => {
    if (subscriptionRef.current != null) {
      channelSubscriptionService.unSubscribe(subscriptionRef.current);
    }
  };
  const IOWrite = (channelName, value) => {
    console.log("Channels: ", { channels });
    console.log("IOWrite: ", { channelName, value });
    if (channelName) {
      const channel = channels.current?.find(obj => {
        return obj.channelName === channelName
      })
      if (channel) {
        channel.data.value = value;
        channelService.Write(channel).then(() => {
        });
      }
    }
  }

  useEffect(() => {
    const fetChannelData = async () => {
      await fetchChannelsStates();
      subscribeToNotificationChannel();
    };
    fetChannelData();
    return () => {
      unSubscribe();
    };
  }, []);
  const handleforward = () => {
    if (forwardModel == 'source') {
      // IOWrite('MAIN.DicingChamberSource_Power.Out.Setpoint', forwardvalue);
      executeCommand(DTDLState["DicingChamberSource_Power"].$dtId, "Setpoint", { setpoint: forwardvalue });
    } else if (forwardModel == 'bias') {
      // IOWrite('MAIN.DicingChamberBias_Power.Out.Setpoint', forwardvalue);
      executeCommand(DTDLState["DicingChamberBias_Power"].$dtId, "Setpoint", { setpoint: forwardvalue });
    }
    setForwardModel(null)
  }
  const SourcemodeFunction = async (e) => {

    let val = !e.RF_ON_Indication;
    if (val == true) {
      executesequence('CheckAndTurnOnDicingSourcePower')
    } else {
      executeCommand(DTDLState["DicingChamberSource_Power"].$dtId, "SetRFONRequest", { rfOn: val });
    }

    // IOWrite('MAIN.DicingChamberSource_Power.Out.RF_ON_Request', val);
    console.log(val)
  }
  const BiasmodeFunction = async (e) => {
    let val = !e.RF_ON_Indication;
   
    executeCommand(DTDLState["DicingChamberBias_Power"].$dtId, "SetRFONRequest", { rfOn: val });
    // IOWrite('MAIN.DicingChamberBias_Power.Out.RF_ON_Request', val);
    console.log(val)
  }
  // -------------------- CHANNEL IMPLIMENTATION----------------END--//
  // -------------------- DTDL IMPLIMENTATION----------------START--//
  const dicingConfigurationDTDL = useRef();
  const subscriptionRefDTDL = useRef();
  const [DTDLState, setDTDLState] = useState(defaultObjectDTDL);
  function flattenDigitalTwinsByName(data) {
    const flattened = {};
    data.digitalTwins.forEach(twin => {
      // console.log('twin', twin)
      flattened[twin.Name] = twin;
    });
    return flattened;
  }
  const fetchObjectState = async () => {
    const response = await objectService.GetObjectGraphById(objectId)

    if (response) {
      dicingConfigurationDTDL.current = response;
      var flattenObject = flattenDigitalTwinsByName(response);
      setDTDLState(() => flattenObject);
      console.log(response, 'DTDL Flaten object')
    }
  };
  const updateDicingStateDTDL = (object) => {

    dicingConfigurationDTDL.current.digitalTwins = dicingConfigurationDTDL.current.digitalTwins.map(dt => {
      if (dt.$dtId === object.$dtId) {
        const updatedObject = { ...dt, ...object };
        return updatedObject;
      }
      return dt;
    });
    var flattenObject = flattenDigitalTwinsByName(dicingConfigurationDTDL.current);
    setDTDLState(() => flattenObject);

  }
  const subscribeToNotificationDTDL = () => {
    const onNewMessageDTDL = (message) => {
      const object = JSON.parse(message);
      console.log("DTDL onNewMessage", object);
      updateDicingStateDTDL(object)
    };
    const objectSubscriptions = dicingConfigurationDTDL.current.digitalTwins.map(fdt => fdt.$dtId);
    const subscription = {
      channels: objectSubscriptions,
      onNewMessage: onNewMessageDTDL,
    };
    subscriptionRefDTDL.current = subscription;
    objectSubscriptionService.subscribeObjects(subscription);
  };
  const unSubscribeDTDL = () => {
    if (subscriptionRefDTDL.current != null) {
      objectSubscriptionService.unSubscribeObjects(subscriptionRefDTDL.current);
    }
  };
  const executeCommand = async (objectId, commandName, params) => {
    const request = {
      instanceId: objectId,
      commandName: commandName,
      parameters: params,
    };
    const response = await objectService.ExecuteCommand(request);

    console.log(response);

  };


  useEffect(() => {
    const fetchState = async () => {
      await fetchObjectState();
      subscribeToNotificationDTDL();
    };
    fetchState();
    return () => {
      unSubscribeDTDL();
    };
  }, []);
  // -------------------- DTDL IMPLIMENTATION----------------END--//

  // const getRunningStatusTestExec = async (currInstanceId) => {
  //   await fetch(
  //     process.env.REACT_APP_VIA_RULE_ORCHES_API +
  //       "/api/Sequence/" +
  //       currInstanceId,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Could not get execution status.");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       let runStatus = data.runtimeStatus;

  //       setRunningStatus(() => runStatus);
  //       if (
  //         runStatus == "TERMINATED" ||
  //         runStatus == "COMPLETED" ||
  //         runStatus == "FAILED"
  //       ) {
  //         clearInterval(execIntervalId);
  //         execIntervalId = 0;
  //         setInstanceId(()=>'');
  //         localStorage.removeItem("dicingChbrLoopInstId")
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Fetch error get running status:", error);
  //     });
  // };

  useEffect(() => {
    if(localStorage.getItem('selStoredRecipe')!=null) {
      setCurrentRecipe(JSON.parse(localStorage.getItem('selStoredRecipe')))
    }
    const interval = setInterval(() => {
      settime(new Date())
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getRecipes = async () => {
    const chambers = await getAllChambers()
    if (chambers) {
      const chamber = chambers.filter((x) => x.chamberName == 'Dicing RIE Chamber')[0]
      const recipes = await getAllRecipesByChamber(chamber.id);
      if (recipes) {
        const sortedRecipes = recipes.DATA.sort((a, b) => {
          return a.recipeName.localeCompare(b.recipeName);
        });
        setRecipes(sortedRecipes);
      }
    }
  };
  const getSequencelist = async () => {
    const sequences = await getAllSequences();
    console.log('dicing sequence', sequences);
    if (sequences && sequences.length > 0) {
      const filteredByRecipe = sequences.filter(sequence => sequence.sequenceType === "RecipeSequence");
      setSequences(filteredByRecipe)
      const selected = sequences.filter((sequence) => sequence.sequenceName === "DicingRecipeSequence");
      setSelectedSequence(selected[0]);
      const filteredByLoopTest = sequences.filter(sequence => sequence.sequenceType === "LoopSequence");
      setLoopTestSequences(filteredByLoopTest)
    }
  }
  const fetchRunningStatus = () => {
    const currInstanceId = localStorage.getItem('dicingChbrLoopInstId');
    const selStoredDicingRecipe = JSON.parse(localStorage.getItem('selStoredDicingRecipe'));
    if (selStoredDicingRecipe && selStoredDicingRecipe.id)
      setSelectedRecipe(() => selStoredDicingRecipe)
    if (currInstanceId && currInstanceId != "") {
      setInstanceId(() => currInstanceId)
      // execIntervalId = setInterval(()=>{
      //   getRunningStatusTestExec(currInstanceId);
      // },1000);
    }
  }
  const executeRecipe = async () => {
    debugger
    if (!selectedRecipe || !selectedRecipe.id) {
      return;
    }
    localStorage.setItem('selStoredRecipe', JSON.stringify(selectedRecipe)); //this has to be changed this is temperary
    setCurrentRecipe(selectedRecipe)   //this has to be changed this is temperary
    var formdata = {
      token: "",
      ruleType: "RecipeSequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: selectedSequence?.sequenceName,
      inputData: {
        RecipeId: selectedRecipe.id,
      },
    };
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not start execution.");
        }
        return response.json();
      })
      .then((data) => {
        let currInstanceId = data[0].resultValue;
        setInstanceId(() => currInstanceId);
        localStorage.setItem('isoEtchChbrLoopInstId', currInstanceId);
        localStorage.setItem('selStoredRecipe', JSON.stringify(selectedRecipe));
         setCurrentRecipe(selectedRecipe)
      })
      .catch((error) => {
        console.error("Fetch error start execution:", error);
      });
  };
  const stopTestExec = async (e) => {
    setRunningStatus(() => 'ABORTING');
    await fetch(
      process.env.REACT_APP_VIA_RULE_ORCHES_API +
      "/api/Sequence/abort/DicingRecipeSequence?execType=RECIPE",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Could not stop execution.');
      }
      return response.json();
    })
      .then(data => {
        if (data.statusCode == 200) {
          // closingRecipe();      
          setTimeout(() => { IOWrite('Status_DicingRecipeSequence', 'TERMINATED'); setRunningStatus(() => 'TERMINATED'); }, 5000)
          localStorage.removeItem('isoEtchChbrLoopInstId')
          clearInterval(execIntervalId)
        } else {
        }

      })
      .catch(error => {
        console.error('Fetch error in stop execution:', error);
      });
  };
  const terminateSequence = async () => {
    let e = localStorage.getItem('currentdicingSequence');

    if (e) {
      await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API +
        "/api/Sequence/terminate?instanceId=" + e,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          }
        }
      ).then(response => {
        // Check if the request was successful
        if (!response.ok) {
          throw new Error('Could not stop execution.');
        }
      })
        .then(data => {
          if (data.statusCode == 200) {
            localStorage.removeItem('currentdicingSequence')
          } else {
          }

        })
        .catch(error => {
          // Handle any errors that occurred during the fetch
          console.error('Fetch error in stop execution:', error);
        });
    }

  };

  const executesequence = async (e) => {
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: e,
      inputData: {},
    };
    try {
      fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      )
        .then(async (response) => {
          console.log('Response status:', response.status);
          console.log('Response text:', await response.text()); // Log the raw response body to inspect it

          // Ensure the response is OK before trying to parse JSON
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Server error or invalid JSON');
          }
        })
        .then((data) => {
          if (data && Array.isArray(data)) {
            const resultValue = data[0]?.resultValue;
            if (resultValue != null) {
              console.log("resultValue print", resultValue);
            }
          }
        })
        .catch((error) => {
          console.error("Parsing error:", error.message);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const CheckDicingInterlock = async () => {
    setOnlineindicator(false)
    try {
      let url = "/api/Interlock/CheckDicingInterlock"

      const response = await fetch(process.env.REACT_APP_VIA_RULE_ORCHES_API + url);
      // Check if the request was successful
      if (response.ok) {
        const result = await response.json();
        // Assuming the API returns a boolean, set the state accordingly
        console.log(' fetch data ok')
        if (result.status == true) {
          executesequence("DicingChamberOnlineSeq")

          console.log(' fetch data success')
        } else {
          setOnlineindicator(true)
        }
      } else {
        console.log('Failed to fetch data')
      }
    } catch (err) {
      console.log(err)
    }
  };
  const handleRecipe = (e) => {
    const val = e.target.value; debugger
    if (val) {
      const selected = recipes.filter((recipe) => recipe.id == e.target.value);
      if (selected[0] && selected[0].id) {
        setSelectedRecipe(selected[0]);
        localStorage.setItem('selStoredDicingRecipe', JSON.stringify(selected[0]));
      } else {
        setSelectedRecipe({});
      }
    } else {
      setSelectedRecipe({});
    }
  };


  useEffect(() => {
    getSequencelist();
    getRecipes();
    // fetchRunningStatus();
  }, []);

  const handleKeyDown = (event) => {
    //console.log(event);
    if (event.key === "Enter") {
      handleforward()
    }
  };
  return (<>
    {/* {DTDLState["DicingChamberPiraniGuage"].OP_State} */}
    {/* {JSON.stringify(DTDLState)} */}
    <div className="flex gap-2 w-full h-full p-1 dysingcontainer-bg">
      <div className="flex flex-1 bg-blue-100 p-3 rounded-md">{time?.toString}

        <div className="w-80">
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-4">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Process Monitor</div>
            <div className="relative w-full h-6 -mt-4">
              <div className="float-right w-10 bg-green-500 h-3 absolute right-1 rounded-full"></div>
            </div>
            <div className="grid grid-cols-6 gap-2 text-xs  font-semibold">
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded- dysing-input col-span-2">{gasBoxState?.find(o => o.name === "PM1.WaferID").currentValue}</div>
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input  col-span-2">Step : {gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepNum").currentValue}</div>
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input  col-span-2">{gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepTime").currentValue} Sec</div>
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input col-span-3">Loop : {gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepLoopCounter").currentValue} / {gasBoxState?.find(o => o.name === "DicingRecipeSequence_StepLoopTotal").currentValue}</div>
              <div class="bg-blue-200 px-2 py-1 flex-1 rounded-sm  dysing-input col-span-3">Time : {gasBoxState?.find(o => o.name === "DicingRecipeSequence_SeqElapsedTime").currentValue} / {gasBoxState?.find(o => o.name === "DicingRecipeSequence_SeqTotalTime").currentValue}</div>

            </div>
            <div className="mb-4">
              <div class=" flex gap-2 mt-4 text-sm">Process Step</div>
              <div className="grid grid-cols-4 gap-2 mt-2">
                <select className="text-sm  bg-gray-200 px-2 py-1 col-span-3 dysing-bg-gray rounded font-semibold text-gray-600"
                  onChange={handleRecipe} >
                  <option>Select Recipe</option>
                  {recipes && recipes.length > 0 &&
                    recipes.map((recipe, i) =>
                      <option key={recipe.id + i + "recipe-list"} value={recipe.id}>{recipe.recipeName}</option>
                    )}
                </select>
                {gasBoxState?.find(o => o.name === "Sequence").currentValue === 'ABORTING' ?
                  <button className=" bg-gray-200 shadow-inner  text-xs py-3 font-semibold rounded hover:shadow-none disabled:text-gray-400"
                    onClick={() => executeRecipe()} disabled>
                    RUN
                  </button>
                  :
                  gasBoxState?.find(o => o.name === "Sequence").currentValue === 'RUNNING' ? (
                    <button
                      onClick={() => stopTestExec()}
                      className="dysing-btn-bg py-1 shadow-md rounded hover:shadow-none "
                    >
                      Abort
                    </button>
                  ) :
                    <button className=" dysing-btn-bg py-1 shadow-md rounded hover:shadow-none "
                      onClick={() => executeRecipe()}>
                      RUN
                    </button>

                }

              </div>
             <div className="mt-3">  {/*{selectedRecipe.recipeName} */}
                {gasBoxState?.find(o => o.name === "Sequence").currentValue == 'ABORTING' ?
                  <span>Aborting recipe  {currentRecipe?.recipeName}</span>
                  : gasBoxState?.find(o => o.name === "Sequence").currentValue == 'TERMINATED' ?
                    <span>Aborted Recipe  {currentRecipe?.recipeName}</span>
                    :
                    gasBoxState?.find(o => o.name === "Sequence").currentValue == 'RUNNING' ?
                      <span>Executing recipe:  {currentRecipe?.recipeName}</span>
                      :
                      <span>Completed recipe:  {currentRecipe?.recipeName}</span>
                }
              </div>
             
            </div>
          </div>
        </div>
        <div className="flex-1 text-center px-1">
          <DicingChamberDTDL executesequence={executesequence} DTDLState={DTDLState} gasBoxState={gasBoxState} IOWrite={IOWrite} executeCommand={executeCommand} setMotorscreen={setMotorscreen} setModescreen={setModescreen} />
        </div>
        <div className="">
          <DicingGasboxDTDL gasBoxState={gasBoxState} IOWrite={IOWrite} />
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1"
              onClick={() => setSourcescreen(!sourcescreen)}
            >Source Power</div>

            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
                onClick={() => SourcemodeFunction(DTDLState["DicingChamberSource_Power"])}>
                <div className="px-2 text-sm font-semibold">
                  {DTDLState["DicingChamberSource_Power"].RF_ON_Indication == true ? "ON" : "OFF"}
                  {/* {gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberSource_Power.Out.RF_ON_Request").currentValue==true ? "ON" : "OFF"} */}
                </div>
                <div className={`h-5 w-5 rounded-full ${DTDLState["DicingChamberSource_Power"].RF_ON_Indication == true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
            </div>
            <div className="grid grid-cols-2 mb-3 gap-2  font-bold text-gray-600 text-sm relative">
              <div className="flex col-span-2">
                <div class="gap-2 mt-1  w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow">
                  <div class="relative z-20 cursor-pointer" onClick={() => { setForwardModel('source'); setForwardvalue(DTDLState["DicingChamberSource_Power"].Actual_Setpoint) }}>
                    {/* {gasBoxState?.find(o => o.name === "DicingChamberSource_PowerSetpoint").currentValue }  */}
                    {DTDLState["DicingChamberSource_Power"]?.Actual_Setpoint?.toFixed(3)}
                  </div>
                </div>
              </div>
              <div className="flex">
                <div class="gap-2 mt-1 w-10">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input w-20">
                  <div class="relative z-20">{DTDLState["DicingChamberSource_Power"]?.Actual_Reflected_Power?.toFixed(3)}</div>
                </div>
              </div>
              <div className="flex">
                <div class="gap-2 mt-1 w-12">C1 \ C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20">0</div>
                </div>
              </div>
              {forwardModel == 'source' &&
                <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                  <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                    <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full'
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                    <div className="flex mt-2 rounded-xl overflow-hidden">
                      <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)} onKeyDown={handleKeyDown}
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' />
                      <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold'
                        onClick={() => handleforward()}>Update</button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="relative bg-gray-100 rounded-md border border-gray-300 px-4 mt-5">
            <div className="-mt-3  mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1"
            >Bias Power</div>
            <div className="justify-end flex -mt-10 mb-5">
              <div className="flex p-1 bg-gray-100 border-gray-400 border rounded-md  hover:scale-105 active:scale-90 transition-all active:bg-white hover:bg-gray-200 cursor-pointer shadow-md"
                onClick={() => BiasmodeFunction(DTDLState["DicingChamberBias_Power"])}>
                <div className="px-2 text-sm font-semibold">
                  {DTDLState["DicingChamberBias_Power"].RF_ON_Indication == true ? "ON" : "OFF"}
                  {/* {gasBoxState?.find(o => o.writeChannelName === "MAIN.DicingChamberBias_Power.Out.RF_ON_Request").currentValue==true ? "ON" : "OFF"} */}
                </div>
                <div className={`h-5 w-5 rounded-full ${DTDLState["DicingChamberBias_Power"].RF_ON_Indication == true ? "bg-flip-label-active" : "bg-gray-500"}`}></div>
              </div>
            </div>
            <div className="grid grid-cols-2 mb-3 gap-2 font-bold text-gray-600 relative text-sm">
              <div className="flex col-span-2">
                <div class="gap-2 mt-1 w-20">Set FWD</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input-yellow ">
                  <div class="relative z-20" onClick={() => { setForwardModel('bias'); setForwardvalue(DTDLState["DicingChamberBias_Power"].Actual_Setpoint) }}>
                    {DTDLState["DicingChamberBias_Power"]?.Actual_Setpoint?.toFixed(3)}</div>
                </div>
              </div>

              <div className="flex">
                <div class="gap-2 mt-1 w-10">RFL</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input  w-20">
                  <div class="relative z-20">{DTDLState["DicingChamberBias_Power"]?.Actual_Reflected_Power?.toFixed(3)}</div>
                </div>
              </div>
              <div className="flex">
                <div class="gap-2 mt-1 w-12">C1 \ C2</div>
                <div class="bg-blue-200 p-1 px-2 flex-1 rounded-md dysing-input ">
                  <div class="relative z-20 ">0</div>
                </div>
              </div>
              {forwardModel == 'bias' &&
                <div class="absolute w-full h-full bg-black bg-opacity-25 z-20 top-0 left-0">
                  <div className='mx-auto w-2/3 bg-white relative shadow-2xl rounded-xl'>
                    <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full'
                      onClick={() => { setForwardModel(null); setForwardvalue(null) }}>x</button>
                    <div className="flex mt-2 rounded-xl overflow-hidden">
                      <input type='number' value={forwardvalue} onChange={(e) => setForwardvalue(e.target.value)}
                        className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' onKeyDown={handleKeyDown} />
                      <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold'
                        onClick={() => handleforward()}>Update</button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="dysing-bg pt-3 px-2 rounded-md w-28">
        {/* <button className="border-4 border-gray-100 dysing-input-green px-2 py-6 text-xs font-bold w-full rounded-lg">ONLINE</button>
        <div className="mt-6 text-xs font-bold text-center">Commands</div> */}
        <div className=" ">
          {/* Online Operation */}
          <button className={`  diceembossed-btn  py-3  !w-full text-xs font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue === 'RUNNING' && '!bg-blue-300 !text-slate-600 '}
         ${onlineindicator == true && 'blink-bg'}
          `}
            // onClick={() => CheckDicingInterlock()}
            disabled={
              gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue === 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue === 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue === 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
                    false}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Online Operation"
            data-tooltip-place="bottom"
            data-tooltip-variant="info"
            onClick={() => setDialogBox("ONLINE")}

          >
            ONLINE {gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue === 'RUNNING' && <><br /><strong className="text-xs font-bold text-gray-800">Running</strong></>}
          </button>
          {dialogBox === "ONLINE" && (
            <div className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 justify-center align-middle ${dialogBox}`}>
              <div className="absolute w-full h-full"
                onClick={() => setDialogBox("")}></div>
              <div className="bg-white rounded-lg  w-[27%] mx-auto relative  top-36">
                <button
                  className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
                  onClick={() => setDialogBox("")}>
                  X
                </button>
                <div className="text-lg p-3 font-bold bg-gray-300">
                  Confirmation for {dialogBox}{" "}
                </div>
                <div className="p-3 gap-3 grid grid-cols-1">
                  <p className="text-slate-600">
                    Do you want to execute the ONLINE operation?
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="bg-gray-300 shadow-shadow100 py-2 px-4 rounded-md text-gray-700 font-bold"
                      onClick={() => setDialogBox("")}>
                      Cancel
                    </button>
                    <button
                      className="bg-[#b9cfe4] shadow-shadow100 font-bold py-2 px-4 rounded-md"
                      onClick={() => {
                        CheckDicingInterlock()
                        setDialogBox("")
                      }}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Pumpdown operation */}
          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs  font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue === 'RUNNING' && '!bg-blue-300 !text-slate-600'}`}
            disabled={
              gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue === 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue === 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue === 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
                    false}
            //onClick={() => executesequence('DicingPumpdownSeq')}
            onClick={() => setDialogBox("PUMPDOWN")}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="PUMP DOWN Operation"
            data-tooltip-place="bottom"
            data-tooltip-variant="info"
          >PUMPDOWN {gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue === 'RUNNING' && <><br /><strong className="text-xs font-bold text-gray-800">Running</strong></>}
          </button>
          {dialogBox === "PUMPDOWN" && (
            <div className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 justify-center align-middle ${dialogBox}`}>
              <div className="absolute w-full h-full"
                onClick={() => setDialogBox("")}></div>
              <div className="bg-white rounded-lg  w-[27%] mx-auto relative  top-36">
                <button
                  className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
                  onClick={() => setDialogBox("")}>
                  X
                </button>
                <div className="text-lg p-3 font-bold bg-gray-300">
                  Confirmation for {dialogBox}{" "}
                </div>
                <div className="p-3 gap-3 grid grid-cols-1">
                  <p className="text-slate-600">
                    Do you want to execute the {dialogBox} operation?
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="bg-gray-300 py-2 px-4 rounded-md text-gray-700 font-bold"
                      onClick={() => setDialogBox("")}>
                      Cancel
                    </button>
                    <button
                      className="bgPrime2 shadow-shadow100 font-bold py-2 px-4 rounded-md"
                      onClick={() => {
                        executesequence('DicingPumpdownSeq')
                        setDialogBox("")
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Vent operation */}
          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue === 'RUNNING' && '!bg-blue-300 !text-slate-600'}`}
            disabled={
              gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue === 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue === 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue === 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
                  gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
                    false}
            //onClick={() => executesequence('DicingVentSeq')}
            onClick={() => setDialogBox("VENT")}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="VENT Operation"
            data-tooltip-place="bottom"
            data-tooltip-variant="info"
          >
            VENT
            {gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue === 'RUNNING' && <><br />
              <strong className="text-xs font-bold text-gray-800">Running</strong></>}
          </button>
          {dialogBox === "VENT" && (
            <div className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 justify-center align-middle ${dialogBox}`}>
              <div className="absolute w-full h-full"
                onClick={() => setDialogBox("")}></div>
              <div className="bg-white rounded-lg  w-[27%] mx-auto relative  top-36">
                <button
                  className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
                  onClick={() => setDialogBox("")}>
                  X
                </button>
                <div className="text-lg p-3 font-bold bg-gray-300">
                  Confirmation for {dialogBox}{" "}
                </div>
                <div className="p-3 gap-3 grid grid-cols-1">
                  <p className="text-slate-600">
                    Do you want to execute the {dialogBox} operation?
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="bg-gray-300 py-2 px-4 rounded-md text-gray-700 font-bold"
                      onClick={() => setDialogBox("")}>
                      Cancel
                    </button>
                    <button
                      className="bgPrime2 shadow-shadow100 font-bold py-2 px-4 rounded-md"
                      onClick={() => {
                        executesequence('DicingVentSeq')
                        setDialogBox("")
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Cycle purge operation */}
          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue === 'RUNNING' && '!bg-blue-300 !text-slate-600'}`}
          disabled={
            gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue === 'RUNNING' ? true :
              gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue === 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue === 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
                  false}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="CYCLE PURGE Operation"
          data-tooltip-place = "bottom"
          data-tooltip-variant = "info"
          onClick={()=>setDialogBox("CYCLE PURGE")}
          >CYCLE PURGE</button>
          {dialogBox === "CYCLE PURGE" && (
            <div className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 justify-center align-middle ${dialogBox}`}>
              <div className="absolute w-full h-full"
                onClick={() => setDialogBox("")}></div>
              <div className="bg-white rounded-lg  w-[27%] mx-auto relative  top-36">
                <button
                  className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
                  onClick={() => setDialogBox("")}>
                  X
                </button>
                <div className="text-lg p-3 font-bold bg-gray-300">
                  Confirmation for {dialogBox}{" "}
                </div>
                <div className="p-3 gap-3 grid grid-cols-1">
                  <p className="text-slate-600">
                    Do you want to execute the {dialogBox} operation?
                  </p>
                  <div className="flex justify-end space-x-4">
                    
                      <div className="flex justify-end space-x-4">
                        <button
                          className="bg-gray-300 py-2 px-4 rounded-md text-gray-700 font-bold"
                          onClick={() => setDialogBox("")}>
                          Cancel
                        </button>
                        <button onClick={() => { executesequence('DicingCyclePurge'); setDialogBox("") }}
                          className="bgPrime2 shadow-shadow100 font-bold py-2 px-4 rounded-md">
                          Confirm
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Leakrate operation */}
          <button className={`diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-5 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400 
          ${gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue === 'RUNNING' && '!bg-blue-300 !text-slate-600'}`}

          disabled={
            gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue === 'RUNNING' ? true :
              gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue === 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue === 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? true :
                gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? true :
                  false}
           data-tooltip-id="my-tooltip"
           data-tooltip-content="LEAK RATE Operation"
           data-tooltip-place = "bottom"
           data-tooltip-variant = "info"
           onClick={()=>setDialogBox("LEAK RATE")}
           >LEAK RATE</button>
           {dialogBox === "LEAK RATE" && (
                 <div className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 justify-center align-middle ${dialogBox}`}>
                  <div className="absolute w-full h-full"
                   onClick={() => setDialogBox("")}></div>
                   <div className="bg-white rounded-lg  w-[27%] mx-auto relative  top-36">
                    <button
                        className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
                        onClick={() => setDialogBox("")}>
                        X
                      </button>
                      <div className="text-lg p-3 font-bold bg-gray-300">
                        Confirmation for {dialogBox}{" "}
                      </div>
                      <div className="p-3 gap-3 grid grid-cols-1">
                      <p className="text-slate-600">
                        Do you want to execute the {dialogBox} operation?
                      </p>
                      <div className="flex justify-end space-x-4">
                      <button
                        className="bg-gray-300 py-2 px-4 rounded-md text-gray-700 font-bold"
                        onClick={() => setDialogBox("")}>
                        Cancel
                      </button>
                      <button onClick={()=>{executesequence('DicingLeakUpRateSeq');setDialogBox("")}}
                        className="bgPrime2 shadow-shadow100 font-bold py-2 px-4 rounded-md">
                        Confirm
                      </button>
                    </div>
                      </div>
                   </div>
                </div>
                
          )}


          {/* Water purge */}
          <button className="diceembossed-btn  py-3  !w-full mt-3 text-sm font-bold leading-8 rounded-lg !text-gray-600 "
            aria-label="Edit in StackBlitz"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="WATER PURGE Operation"
            data-tooltip-place="bottom"
            data-tooltip-variant="info"
            // onClick={() => setDialogBox("WATER PURGE")}
          >WATERPURGE</button>
          {dialogBox === "WATER PURGE" && (
            <div className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 justify-center align-middle ${dialogBox}`}>
              <div className="absolute w-full h-full"
                onClick={() => setDialogBox("")}></div>
              <div className="bg-white rounded-lg  w-[27%] mx-auto relative  top-36">
                <button
                  className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
                  onClick={() => setDialogBox("")}>
                  X
                </button>
                <div className="text-lg p-3 font-bold bg-gray-300">
                  Confirmation for {dialogBox}{" "}
                </div>
                <div className="p-3 gap-3 grid grid-cols-1">
                  <p className="text-slate-600">
                    Do you want to execute the {dialogBox} operation?
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="bg-gray-300 py-2 px-4 rounded-md text-gray-700 font-bold"
                      onClick={() => setDialogBox("")}>
                      Cancel
                    </button>
                    <button
                      className="bgPrime2 shadow-shadow100 font-bold py-2 px-4 rounded-md">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <button className=" diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg !text-gray-600  ">PURGE</button> */}



          <button className="diceembossed-btn  py-3  !w-full mt-3 text-xs font-bold leading-8 rounded-lg text-gray-600 hover:bg-slate-200  disabled:bg-slate-300 disabled:text-slate-400"
            //onClick={() => terminateSequence()}
            onClick={() => setDialogBox("ABORT")}

            disabled={
              gasBoxState?.find(o => o.name === "Status_DicingChamberOnlineSeq").currentValue == 'RUNNING' ? false :
                gasBoxState?.find(o => o.name === "Status_DicingVentSeq").currentValue == 'RUNNING' ? false :
                  gasBoxState?.find(o => o.name === "Status_DicingPumpdownSeq").currentValue == 'RUNNING' ? false :
                  gasBoxState?.find(o => o.name === "Status_DicingCyclePurge").currentValue == 'RUNNING' ? false :
                  gasBoxState?.find(o => o.name === "Status_DicingLeakUpRateSeq").currentValue == 'RUNNING' ? false :
                    true}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="ABORT Current Operation"
            data-tooltip-place="top"
            data-tooltip-variant="info"

          >ABORT</button>
          {dialogBox === "ABORT" && (
            <div className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 justify-center align-middle ${dialogBox}`}>
              <div className="absolute w-full h-full"
                onClick={() => setDialogBox("")}></div>
              <div className="bg-white rounded-lg  w-[27%] mx-auto relative  top-36">
                <button
                  className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
                  onClick={() => setDialogBox("")}>
                  X
                </button>
                <div className="text-lg p-3 font-bold bg-gray-300">
                  Confirmation for {dialogBox}{" "}
                </div>
                <div className="p-3 gap-3 grid grid-cols-1">
                  <p className="text-slate-600">
                    Do you want to {dialogBox} Current operation?
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="bg-gray-300 py-2 px-4 rounded-md text-gray-700 font-bold"
                      onClick={() => setDialogBox("")}>
                      Cancel
                    </button>
                    <button
                      className="bgPrime2 shadow-shadow100 font-bold py-2 px-4 rounded-md"
                      onClick={() => terminateSequence()}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        <Tooltip id="my-tooltip" />

      </div>
    </div>
    {motorscreen &&
      <div className="bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden z-30" >
        <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setMotorscreen(!motorscreen)}></div>
        <div className="mx-auto w-80 dysing-motormodel p-5 mt-4 relative z-2">
          <div className="-mt-7 mb-3 flex">
            <div className="flex-1">
              <div className="  bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Motor</div>
            </div>
            <div>
              <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setMotorscreen(!motorscreen)}>✖</button>
            </div>
          </div>
          <DicingMoterModelDTDL DTDLState={DTDLState} gasBoxState={gasBoxState} IOWrite={IOWrite} executeCommand={executeCommand} />
        </div>
      </div>}
    {modescreen &&
      <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
        <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setModescreen(!modescreen)}></div>
        <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
          <div className="-mt-7 mb-3 flex">
            <div className="flex-1">
              <div className="  bg-gray-100 w-20 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Mode</div>
            </div>
            <div>
              <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setModescreen(!modescreen)}>✖</button>
            </div>
          </div>
          <DicingPressureModeDTDL DTDLState={DTDLState} gasBoxState={gasBoxState} IOWrite={IOWrite} executeObject={executeCommand} setModescreen={setModescreen} modescreen={modescreen} />
        </div>
      </div>
    }
    {biasscreen &&
      <div className={`bg-black bg-opacity-10 absolute top-0 left-0 w-full h-full overflow-hidden  z-30 `}>
        <div className="absolute h-full w-full top-1 left-1 z-0 " onClick={() => setBiasscreen(!biasscreen)}></div>
        <div className="mx-auto w-80 dysing-motormodel p-5 mt-36 relative z-2">
          <div className="-mt-7 mb-3 flex">
            <div className="flex-1">
              <div className="  bg-gray-100 w-28 rounded-md border-gray-400 border text-center font-bold  text-sm text-gray-600 py-1">Bias Power</div>
            </div>
            <div>
              <button className="bg-gray-100 rounded-md border-gray-400 border px-1.5 text-gray-500 hover:bg-red-500 transition-all hover:text-white" onClick={() => setBiasscreen(!biasscreen)}>✖</button>
            </div>
          </div>
          <DicingBiasPoverModel gasBoxState={gasBoxState} IOWrite={IOWrite} executeCommand={executeCommand} setBiasscreen={setBiasscreen} biasscreen={biasscreen} />
        </div>


      </div>
    }

  </>);
}