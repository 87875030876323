import React, { useEffect, useRef, useState, useReducer } from "react";
import { ObjectService, ObjectSubscriptionService } from "@getvia/sdk";

const objectId = "dtmi:com:via:Subsystem:DicingRIEGasBox;1"; //TODO: make it configurable
const manifoldObjectId = "dtmi:com:via:Module:Pneumatic Controllers;1"

const viaConfig = {
  objectServiceOptions: {
    apiToken: 'test',
    baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
    wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB
  }
};
const objectService = new ObjectService(viaConfig.objectServiceOptions);
const objectSubscriptionService = new ObjectSubscriptionService(viaConfig.objectServiceOptions);
const defaultGasBoxState = {
  "MFC7": {
    "State": 0,
    "FlowReading": 0,
    "FlowSetpoint": 0,
  },
  "MFC8": {
    "State": 0,
    "FlowReading": 0,
    "FlowSetpoint": 0
  },
  "MFC9": {
    "State": 0,
    "FlowReading": 0,
    "FlowSetpoint": 0
  },
  "MFC10": {
    "State": 0,
    "FlowReading": 0,
    "FlowSetpoint": 0
  },
  "MFC11": {
    "State": 0,
    "FlowReading": 0,
    "FlowSetpoint": 0
  },
  "MFC12": {
    "State": 0,
    "FlowReading": 0,
    "FlowSetpoint": 0
  },
  "DownStreamValve7": {
    "CloseOpenCmd": false
  },
  "DownStreamValve8": {
    "OnOff": false,
  },
  "DownStreamValve9": {
    "OnOff": false
  },
  "DownStreamValve10": {
    "OnOff": false
  },
  "DownStreamValve11": {
    "OnOff": false
  },
  "DownStreamValve12": {
    "OnOff": false
  },
  "UpStreamValve7": {
    "OnOff": false
  },
  "UpStreamValve8": {
    "OnOff": false
  },
  "UpStreamValve9": {
    "OnOff": false
  },
  "UpStreamValve10": {
    "OnOff": false
  },
  "UpStreamValve11": {
    "OnOff": false
  },
  "UpStreamValve12": {
    "OnOff": false
  },
  "Shared Devices": {
    "State": "undefined",
  },
  "Manifold1": {
    "OP_State": 0,
  },
  "Manifold2": {
    "OP_State": 0,
  },
  "Manifold3": {
    "OP_State": 0,
  }
}

const defaultManifoldState = {
  "Shared Devices": {
    "State": "undefined",
  },
  "Manifold1": {
    "OP_State": 0,
  },
  "Manifold2": {
    "OP_State": 0,
  },
  "Manifold3": {
    "OP_State": 0,
  }
}

  
export default function DicingGasboxDTDL({gasBoxState,IOWrite}) {
    const gasBoxConfiguration = useRef();
    const manifoldConfiguration = useRef();
    const manifoldSubscriptRef = useRef();
    const subscriptionRef = useRef();
    const [DTDLState, setDTDLState] = useState(defaultGasBoxState);
    const [manifoldState, setManifoldState] = useState(defaultManifoldState);
    const throttleObjectId = "dtmi:com:via:Throttle:DicingChamberThrottleValve;1"
    const [throttleActionMsg, setThrottleActionMsg] = useState("")

    const [selectedValve, setselectedValve] = useState(null);
    const [Valvetitle, setValvetitle] = useState(null);
    const [selectedMfc, setselectedMfc] = useState(null);


    const [mfcvalue, setmfcvalue] = useState(null);
    function flattenDigitalTwinsByName(data) {
      const flattened = {};
      data.digitalTwins.forEach(twin => {
        flattened[twin?.Name] = twin;
      });
      return flattened;
    }
    const fetchObjectState = async () => {
      const response = await objectService.GetObjectGraphById(objectId)
      let flattenObject = {}
      let flattenManifoldObject = {}
      let gasBoxResponseData = {}
      let manifoldResponseData = {}
      if (response) {
        gasBoxResponseData = response
        flattenObject = flattenDigitalTwinsByName(response);
      }

      const manifoldResponse = await objectService.GetObjectGraphById(manifoldObjectId)
      if(manifoldResponse) {
        manifoldConfiguration.current = manifoldResponse
        manifoldResponseData = manifoldResponse
        flattenManifoldObject = flattenDigitalTwinsByName(manifoldResponse);
      }

      const digitalTwins = [...gasBoxResponseData.digitalTwins, ...manifoldResponseData.digitalTwins]
      const relationships = [...gasBoxResponseData.relationships, ...manifoldResponseData.relationships]

      gasBoxConfiguration.current = {digitalTwins, relationships}
      const combineBoth = {...flattenObject, ...flattenManifoldObject}
      setDTDLState(() => combineBoth);
    };

    const updateGasBoxState = (object) => {
      gasBoxConfiguration.current.digitalTwins = gasBoxConfiguration.current.digitalTwins.map(dt => {
        if (dt.$dtId === object.$dtId) {
          const updatedObject = { ...dt, ...object};
          return updatedObject;
        }
        return dt;
      });
      var flattenObject = flattenDigitalTwinsByName(gasBoxConfiguration.current);
      setDTDLState(() => flattenObject);
  
    }
    const subscribeToNotification = () => {

      const onNewMessage = (message) => {
        const object = JSON.parse(message);
        updateGasBoxState(object)
      };
      const objectSubscriptions = gasBoxConfiguration.current.digitalTwins.map(fdt => fdt.$dtId);
      const manifoldObjectSubscriptions = manifoldConfiguration.current.digitalTwins.map(fdt => fdt.$dtId);
      const combinedBothObjs = [...objectSubscriptions, ...manifoldObjectSubscriptions]
      const subscription = {
        channels: combinedBothObjs,
        onNewMessage: onNewMessage,
      };
      subscriptionRef.current = subscription;
      objectSubscriptionService.subscribeObjects(subscription);
    };


 const unSubscribe = () => {
    objectSubscriptionService.unSubscribeObjects(subscriptionRef.current);
  };
  useEffect(() => {
    const fetchState = async () => {
      await fetchObjectState();
      subscribeToNotification();
    };
    fetchState();
    return () => {
      unSubscribe();
    };
  }, []);

  // const executeCommand = async (objectId, commandName, params) => {
  //   const request = {
  //     instanceId: objectId,
  //     commandName: commandName,
  //     parameters: params
  //   }
  //   const response = await objectService.ExecuteCommand(request);

  //   console.log(response);
  // }
  const executeCommand = async (objectId, commandName, params) => {
    const request = {
      instanceId: objectId,
      commandName: commandName,
      parameters: params,
    };
    const response = await objectService.ExecuteCommand(request);
  
    // console.log(response);
    if(objectId===throttleObjectId && !response.success){
      setThrottleActionMsg(()=>response.message.replace("Error invoking method:",""))
      setTimeout(()=>setThrottleActionMsg(""),5000)
    }
    return response;
  };
  const handleValveClick = (value) => {
    var dtObject = DTDLState[selectedValve];
    executeCommand(dtObject.$dtId, "CloseOpenCmd", {
      OnOff: value
    });
    dtObject["OnOff"] = value;

    setselectedValve(null)
  };
  const valveseter = (selectedValve,value) => {
    var dtObject = DTDLState[selectedValve];
    executeCommand(dtObject.$dtId, "CloseOpenCmd", {
      OnOff: value
    });
    dtObject["OnOff"] = value;
  };
  const executeSequence = async (Sequence) => {
    var formdata = {
      token: "",
      ruleType: "Sequence",
      ruleGroups: [],
      ruleSubGroups: [],
      rule: "",
      sequence: Sequence,
      inputData: {},
    };
    try {
       await fetch(
        process.env.REACT_APP_VIA_RULE_ORCHES_API + "/api/Rules/ExecuteRule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      ).then(response => response.json())
      .then(data => {
          const resultValue = data[0].resultValue;    
          if(resultValue!=null){
            console.log('resultValue print',resultValue);   
          } 
      });
    } catch (error) {
      // console.error("Error:", error);
    }
  }
    const handleMfcvalue = async () => {
    //   var dtObject = DTDLState[selectedMfc];
    //   let a = await executeCommand(dtObject.$dtId, "SetFlowSP", {
    //     FlowSP: mfcvalue
    //   });
    //  if(a.success==true){
        if(selectedMfc=="MFC7") { 
          IOWrite("DicingChamberMFCValues_MFC7",mfcvalue)
          executeSequence("CheckAndSetFlowRateDicingMFC7")
        } else
        if(selectedMfc=="MFC8") {
          IOWrite("DicingChamberMFCValues_MFC8",mfcvalue)
          executeSequence("CheckAndSetFlowRateDicingMFC8")
        } else
        if(selectedMfc=="MFC9") { 
          IOWrite("DicingChamberMFCValues_MFC9",mfcvalue)
          executeSequence("CheckAndSetFlowRateDicingMFC9")
        } else
        if(selectedMfc=="MFC10") { 
          IOWrite("DicingChamberMFCValues_MFC10",mfcvalue)
          executeSequence("CheckAndSetFlowRateDicingMFC10")
        } else
        if(selectedMfc=="MFC11") { 
          IOWrite("DicingChamberMFCValues_MFC11",mfcvalue)
          executeSequence("CheckAndSetFlowRateDicingMFC11")
        } else
        if(selectedMfc=="MFC12") {
          IOWrite("DicingChamberMFCValues_MFC12",mfcvalue)
          executeSequence("CheckAndSetFlowRateDicingMFC12")
        }        
    //  }

      // if (dtObject["State"] !== 8) {
      //   window.confirm(`${selectedMfc} is not operational, current state :${dtObject["State"]}`)
      //   return;
      // }
      setselectedMfc(null);
      setmfcvalue(null)  
    }

    const handleKeyDown = (event) => {
      //console.log(event);
      if (event.key === "Enter") {
        handleMfcvalue();
      }
    };


    // useEffect(() => {
    //   setInterval(() => {
    //     var mani = {...manifoldState};
    //     mani.Manifold1.OP_State = 0
    //     setManifoldState(() => mani);
    //   }, 5000)
    // }, [])

  return (<>
    {/* <div className={`border-2 absolute top-10 left-10 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState["DownStreamValve7"].OnOff ?'bg-green-600':'bg-black'}`}>Testing</div> */}
      <div className="relative">
        <div className="relative rounded-t-xl border-b-0 border-2 border-gray-400 mt-1">
          <div className="-mt-3 ml-2 mb-3 bg-gray-100 w-40 rounded-md border-gray-400 border text-center font-bold text-sm text-gray-600 py-1">GAS Controller</div>
          <div className="flex flex-col gap-2 mt-2 -ml-0.5">
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">He</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve7"); setValvetitle('CF4 Down Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 
                      ${
                        DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : 
                        DTDLState["DownStreamValve7"].OnOff ? 'bg-green-600' : 'bg-black'}
                      `} ></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" 
                value={gasBoxState?.find(o => o.name === "MAIN.MFC7.Out.Flow_SP").currentValue} 
                // value={DTDLState["MFC7"].FlowSetpoint} 
                 onClick={() => { setselectedMfc("MFC7"); setmfcvalue(gasBoxState?.find(o => o.name === "MAIN.MFC7.Out.Flow_SP").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input   rounded" value={DTDLState["MFC7"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer"
                    onClick={() => { setselectedValve("UpStreamValve7"); setValvetitle('CF4 Up Valve 1') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["UpStreamValve7"].OnOff  ? 'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">C4F8</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve8"); setValvetitle('C4F8 Down Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["DownStreamValve8"].OnOff?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" 
                value={gasBoxState?.find(o => o.name === "MAIN.MFC8.Out.Flow_SP").currentValue} 
                // value={DTDLState["MFC8"].FlowSetpoint}
                 onClick={() => { setselectedMfc("MFC8"); setmfcvalue(gasBoxState?.find(o => o.name === "MAIN.MFC8.Out.Flow_SP").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={DTDLState["MFC8"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve8"); setValvetitle('C4F8 Up Valve 2') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["UpStreamValve8"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">CH4</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve9"); setValvetitle('CH4 Down Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["DownStreamValve9"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" 
                value={gasBoxState?.find(o => o.name === "MAIN.MFC9.Out.Flow_SP").currentValue} 
                // value={DTDLState["MFC9"].FlowSetpoint} 
                onClick={() => { setselectedMfc("MFC9"); setmfcvalue(gasBoxState?.find(o => o.name === "MAIN.MFC9.Out.Flow_SP").currentValue) }}/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                 <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={DTDLState["MFC9"].FlowReading} readonly="true"/>
                 <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve9"); setValvetitle('CH4 Up Valve 3') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["UpStreamValve9"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">O2</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve10"); setValvetitle('O2 Down Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["DownStreamValve10"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" 
                value={gasBoxState?.find(o => o.name === "MAIN.MFC10.Out.Flow_SP").currentValue} 
                // value={DTDLState["MFC10"].FlowSetpoint} 
                onClick={() => { setselectedMfc("MFC10"); setmfcvalue(gasBoxState?.find(o => o.name === "MAIN.MFC10.Out.Flow_SP").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={DTDLState["MFC10"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve10"); setValvetitle('O2 Up Valve 4') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["UpStreamValve10"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 w-16 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-sm">Ar</div>
                <div className="shadow-inner h-2 w-3 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve11"); setValvetitle('AR Down Valve 5') }} >
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["DownStreamValve11"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0 rounded dysing-input-yellow cursor-pointer" 
                value={gasBoxState?.find(o => o.name === "MAIN.MFC11.Out.Flow_SP").currentValue} 
                // value={DTDLState["MFC11"].FlowSetpoint} 
                onClick={() => { setselectedMfc("MFC11"); setmfcvalue(gasBoxState?.find(o => o.name === "MAIN.MFC11.Out.Flow_SP").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4  dysing-input rounded" value={DTDLState["MFC11"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve11"); setValvetitle('AR Up Valve 5') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["UpStreamValve11"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
            <div className="flex">
                <div className="rounded-r-lg border-2 border-gray-400 px-0.5 text-center font-semibold bg-gray-100 h-7 mt-0.5 text-xs pt-1">Backside He</div>
                <div className="shadow-inner h-2 w-1 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("DownStreamValve12"); setValvetitle('HE Down Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["DownStreamValve12"].OnOff ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input type="" class="h-6 w-12 text-center py-4 px-1 pr-0  dysing-input-yellow rounded cursor-pointer" 
                value={gasBoxState?.find(o => o.name === "MAIN.MFC12.Out.Flow_SP").currentValue} 
                // value={DTDLState["MFC12"].FlowSetpoint} 
                onClick={() => { setselectedMfc("MFC12"); setmfcvalue(gasBoxState?.find(o => o.name === "MAIN.MFC12.Out.Flow_SP").currentValue) }}/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <input class="h-6 w-12 text-center py-4 dysing-input rounded" value={DTDLState["MFC12"].FlowReading} readonly="true"/>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
                <button className="shadow-inner h-8 w-5 shadow-gray-500  border-2 border-gray-400 rounded pt-1 cursor-pointer" 
                    onClick={() => { setselectedValve("UpStreamValve12"); setValvetitle('HE Up Valve 6') }}>
                    <div className={`border-2 border-gray-100  h-5 w-5 rounded-full -ml-0.5 scale-125 ${DTDLState['Manifold1']?.OP_State !== 8 ? 'bg-red-600' : DTDLState["UpStreamValve12"].OnOff  ?'bg-green-600':'bg-black'}`}></div>
                </button>
                <div className="shadow-inner h-2 w-6 shadow-gray-500 mt-3 border-b-2 border-t border-gray-400"></div>
            </div>
          </div>
        </div>
        
        {selectedMfc != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-auto w-2/3 bg-white  mt-36 relative shadow-2xl rounded-xl'>
              <button className='bg-red-500 px-2 text-white absolute -right-3 -top-3 rounded-full' onClick={() => { setselectedMfc(null); setmfcvalue(null) }}>x</button>
               <div className="flex mt-2 rounded-xl overflow-hidden">
                <input type='number' value={mfcvalue ? mfcvalue : ''} onChange={(e) => setmfcvalue(e.target.value)} className='p-2 text-sm border bg-gray-100 w-full font-bold flex-1' placeholder='change value' onKeyDown={handleKeyDown}/>
                <button className='bg-gray-600 rounded hover:bg-gray-400 text-white px-3 py-1 text-xs font-bold' onClick={() => handleMfcvalue()}>Update</button>
              </div>
              
              
            </div>
          </div>
        }
            {selectedValve != null &&
          <div class="absolute w-full h-full bg-black bg-opacity-25 top-0 left-0">
            <div className='mx-5 bg-white p-4 mt-16 relative shadow-2xl rounded-lg'>
              <div className='text-base font-medium'>{DTDLState[selectedValve].OnOff === true ? 'Do you want to close ' : 'Do you want to open '} <span className="font-bold">{Valvetitle}</span> ?</div>
              <div className='flex justify-between gap-3'>
              {DTDLState[selectedValve].OnOff=== true ?
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded font-bold flex-1' onClick={() => { handleValveClick(false); }}>Yes</button>
                  :
                  <button className='bg-white-500 shadow-md hover:bg-gray-300 border border-b text-black px-3 py-1 mt-3 rounded  font-bold flex-1' onClick={() => handleValveClick(true)}>Yes</button>
                }
                <button className='bg-gray-600 text-white px-3 py-2 mt-3 rounded flex-1' onClick={() => setselectedValve(null)}>No</button>
              </div>

             </div>
          </div>
        }
    </div>


  </>);
}